import { IconButton, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/system";
import * as React from "react";
import { DefaultTheme } from "../styling/theme";

export interface AppRootProps extends React.PropsWithChildren {
  queryDevTools?: boolean;
}

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AppContextProvider } from "./app_context/app_context_provider";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SialogicQueryClient } from "./sialogic_query_client";
import { closeSnackbar, SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import { Close } from "@mui/icons-material";

export const AppRoot: React.FunctionComponent<AppRootProps> = ({
  queryDevTools = false,
  ...props
}) => {
  return (
    <QueryClientProvider client={SialogicQueryClient}>
      <AppContextProvider>
        <StyledEngineProvider injectFirst>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={DefaultTheme}>
              <SnackbarProvider
                autoHideDuration={5000}
                maxSnack={3}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                action={(snackbarId) => (
                  <IconButton
                    size="small"
                    onClick={() => closeSnackbar(snackbarId)}
                  >
                    <Close />
                  </IconButton>
                )}
              >
                <ConfirmProvider
                  defaultOptions={{
                    title: I18n.t("base.confirm"),
                    description: I18n.t("base.are_you_sure"),
                    cancellationText: I18n.t("base.cancel"),
                    confirmationText: I18n.t("base.yes"),
                  }}
                >
                  {props.children}
                </ConfirmProvider>
              </SnackbarProvider>
            </ThemeProvider>
            {queryDevTools ? (
              <ReactQueryDevtools
                buttonPosition="bottom-left"
                client={SialogicQueryClient}
                initialIsOpen={false}
              />
            ) : null}
          </DndProvider>
        </StyledEngineProvider>
      </AppContextProvider>
    </QueryClientProvider>
  );
};
