import Bluebird from "bluebird";
import { defaultTo, get, has, isEmpty, isNil, isString } from "lodash";
import Rails from "@rails/ujs";

Bluebird.config({ cancellation: true }); // enable cancelation

export class HttpError<RequestType = JQuery.jqXHR> extends Error {
  response: string;
  request: RequestType;
  error: Error;

  constructor(request: RequestType, error: Error = null) {
    super("Request failed");
    this.request = request;
    this.error = error; // store the original error
    if (has(request, "responseJSON.errors.0.detail")) {
      this.response = get(request, "responseJSON.errors.0.detail") as string;
    } else if (has(request, "responseJSON.error")) {
      this.response = get(request, "responseJSON.error") as string;
    } else if (has(request, "responseText")) {
      this.response = get(request, "responseText") as string;
    }

    this.name = "HttpError";
    this.stack = new Error().stack;
  }
}

/**
 * Fade in a jquery element and return a promise for completion of effect
 * @param element The element to fade in
 * @param duration_easing The duration or easing option for the animation
 * @return A promise that is resolved when the animation is completed
 */
export function fadeIn(
  element: JQuery,
  duration_easing: JQuery.Duration | string = 0,
): Bluebird<void> {
  // fade in callbacks only work if an element is found -> skip fading
  if (isEmpty(element)) {
    return Bluebird.resolve();
  }

  return new Bluebird((resolve, reject) => {
    try {
      element.fadeIn(duration_easing, resolve);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Fade out a jquery element and return a promise for completion of effect
 * @param element The element to fade out
 * @param duration_easing The duration or easing option for the animation
 * @return A promise that is resolved when the animation is completed
 */
export function fadeOut(
  element: JQuery,
  duration_easing: JQuery.Duration | string = 0,
): Bluebird<void> {
  // fade out callbacks only work if an element is found -> skip fading
  if (isEmpty(element)) {
    return Bluebird.resolve();
  }

  return new Bluebird((resolve, reject) => {
    try {
      element.fadeOut(duration_easing, resolve);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Load data from a url. The request is cancable with Promise.cancel()
 * @param url Url to load
 * @template T Type of response data, e.g., string for plain text requests, Objects for json requests
 * @return A Promise to the response
 */
export function loadDataFromUrl<T>(
  url: string | URL,
  dataType: "json" | "text" | "bin" = "json",
): Bluebird<T> {
  return new Bluebird<T>((resolve, reject, onCancel) => {
    const controller = new AbortController();
    const signal = controller.signal;
    let isAborted = false;
    const request = fetch(isString(url) ? url : url.toString(), { signal })
      .then((response) => {
        if (isAborted) {
          return;
        }
        if (!response.ok) {
          reject(new HttpError(request));
        } else {
          if (dataType == "json") {
            resolve(response.json() as T);
          } else if (dataType == "text") {
            resolve(response.text() as T);
          } else if (dataType == "bin") {
            resolve(response.arrayBuffer() as T);
          }
        }
      })
      .catch((e) => {
        if (!isAborted) {
          reject(e);
        }
      });

    // register cancel callback
    onCancel(() => {
      isAborted = true;
      controller.abort();
    });
  });
}

/**
 *
 *
 * @export
 * @param {string} url Url to fetch
 * @return {*}  {Bluebird<Uint8Array>}
 *
 */
export function loadBinaryData(url: string | URL): Bluebird<Uint8Array> {
  return loadDataFromUrl<ArrayBuffer>(url, "bin").then(
    (arrayBuffer) => new Uint8Array(arrayBuffer),
  );
}

export type RequestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

/** @see sendData()
 * Sends data to a json api with fixed content type of "application/vnd.api+json"
 *
 * @export
 * @template BodyType
 * @template ResponseType
 * @param {(string | URL)} url
 * @param {BodyType} body
 * @param {RequestMethod} [method="POST"]
 * @return {*}  {Bluebird<ResponseType>}
 */
export function sendJsonApiData<BodyType, ResponseType>(
  url: string | URL,
  body: BodyType,
  method: RequestMethod = "POST",
): Bluebird<ResponseType> {
  return sendData<BodyType, ResponseType>(
    url,
    body,
    method,
    "application/vnd.api+json",
  );
}
/**
 * Send json data to a url
 * @param url Url to send data to
 * @param body The payload
 * @param method HTTP method
 * @template BodyType Type of the Body data to send
 * @template ResponseType Type of the Response data expected to be received
 * @return A Promise to the response
 * @throws {HttpError<JQuery.jqXHR<ResponseType>>}
 */
export function sendData<BodyType, ResponseType>(
  url: string | URL,
  body: BodyType,
  method: RequestMethod = "POST",
  contentType: string = null,
  headers?: Record<string, string | null | undefined>,
  dataType?: string,
): Bluebird<ResponseType> {
  return new Bluebird<ResponseType>((resolve, reject, onCancel) => {
    let isAborted = false;

    headers = headers || {};
    if (method !== "GET") {
      headers["X-CSRF-Token"] = Rails.csrfToken();
    }

    const request = $.ajax({
      url: isString(url) ? url : url.toString(),
      method: method,
      contentType: defaultTo(contentType, "application/json"),
      dataType,
      headers,
      data: isNil(body) ? null : JSON.stringify(body),
      success: (response: ResponseType) => {
        if (isAborted) {
          return;
        }

        resolve(response);
      },

      error: (request: JQuery.jqXHR<ResponseType>) => {
        if (isAborted) {
          return;
        }

        reject(new HttpError(request));
      },
    }) as JQuery.jqXHR<ResponseType>;

    // register cancel callback
    onCancel(() => {
      isAborted = true;
      request.abort();
    });
  });
}
